








































import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName, Identifier, Picker, Related, RelatedType, SizeMap } from '@d24/modules'

import { Alert } from '@component/Alert'
import { Inject } from '@plugin/inversify'
import { PickerPayload } from '@module/content/contracts'

import { EditFileData } from './EditFileData.vue'
import { EditFilesDataPayload, FileData, FileDataActions, FileRepositoryType, IFileRepository, StoredFileData } from '../contracts'

@Component({
  name: 'EditFilesDataModal',
  components: { EditFileData, Alert }
})
export class EditFilesDataModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: EditFilesDataPayload

  @Inject(FileRepositoryType)
  protected fileRepository!: IFileRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  public dataFromApi: Record<Identifier, FileData> = {}
  public files: FileData[] = []
  public icons = DashmixIconName
  public loading: boolean = false

  created () {
    this.getFilesData()
  }

  public cancel () {
    this.modalConnector.close()
  }

  public save () {
    if (!this.payload.onSuccess || typeof this.payload.onSuccess !== 'function') {
      throw new Error('Wrong onSuccess method in [EditFilesDataModal]')
    }

    const files: StoredFileData[] = this.files.map(f => {
      const file: StoredFileData = {
        action: f.action ? f.action : FileDataActions.None,
        isAuthorChange: false,
        isCaptionChange: false,
        shortDesc: f.caption,
        author: f.author,
        caption: f.caption,
        redirectExt: f.redirectExt,
        image: { value: f.id, type: RelatedType.File }
      }

      if (f.author !== this.dataFromApi[f.id].author) {
        file.isAuthorChange = true
      }

      if (f.caption !== this.dataFromApi[f.id].caption) {
        file.isCaptionChange = true
      }

      return file
    })

    this.payload.onSuccess(files)
  }

  public returnToPreviousStep () {
    const selected: Related<RelatedType.File, Identifier>[] = []
    const onPickerSelection = (
      data: Related<RelatedType.File, Identifier> | Related<RelatedType.File, Identifier>[]
    ) => {
      if (Array.isArray(data)) {
        selected.push(...data)
      } else {
        selected.push(data)
      }
    }

    const pickerPayload: PickerPayload<Picker.Media, RelatedType.File, Identifier> = {
      multiple: true,
      onSelection: onPickerSelection,
      selected: this.files.map(file => {
        return {
          value: file.id,
          type: RelatedType.File
        }
      })
    }

    const modalPayload: EditFilesDataPayload = {
      selected: selected,
      filesData: this.files,
      onSuccess: this.payload.onSuccess
    }

    this.$emit('swap', {
      toOpen: Picker.Media,
      payload: pickerPayload,
      override: modalPayload,
      config: { size: SizeMap.XLarge }
    })

    this.modalConnector.close()
  }

  private getFilesData () {
    const promises: Promise<void>[] = []

    this.loading = true

    if (this.payload.selected.length < 1) {
      return
    }

    // TODO - check for one API call when API ready
    this.payload.selected.forEach(related => {
      promises.push(
        this.fileRepository.loadFile(related.value).then(file => {
          this.dataFromApi[file.id] = file as unknown as FileData
        })
      )
    })

    Promise.all(promises).then(() => {
      this.mergeData()
    }).catch(e => console.warn(e)
    ).finally(() => {
      this.loading = false
    })
  }

  private mergeData () {
    const mapped = this.payload.filesData.reduce((prev: AnyObject, current: FileData) => {
      prev[current.id] = current
      return prev
    }, {} as AnyObject) as AnyObject

    const files = []

    for (const key in this.dataFromApi) {
      files.push({
        ...this.dataFromApi[key],
        ...mapped[key]
      })
    }

    this.files = files
  }

  public onItemUpdate (model: FileData) {
    this.files = this.files.map(file => {
      if (file.id === model.id) {
        return model
      } else {
        return file
      }
    })
  }
}

export default EditFilesDataModal
