















import { Component } from 'vue-property-decorator'
import { DashmixButtonVariantMap, DashmixTheme } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'

import { Figure } from '@component/Figure/Figure'
import { Inject } from '@plugin/inversify'
import { Modals } from '@/config/modals'
import { ModalSize } from '@component/Modal'

import { Addon } from '../../maps/variant'
import { Meta } from '../../contracts'

import { AbstractAddon } from './AbstractAddon'
import { AddonErrors } from './AddonErrors.vue'
import { MetaRobots } from './MetaRobots.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'MetaAddon',
  components: { AddonErrors, Figure, MetaRobots }
})
export class MetaAddon extends AbstractAddon {
  @Inject(ModalType)
  protected modalConnector!: IModal

  public buttonTheme = DashmixTheme
  public buttonVariant = DashmixButtonVariantMap

  public get meta (): Meta {
    return this.variant.getProperty<Meta>(Addon.Meta) ?? {} as Meta
  }

  public set meta (meta: Meta) {
    this.variant.setProperty<Meta>(Addon.Meta, meta)
    this.onChange(this.variant)
  }

  public openModal () {
    this.modalConnector.open(Modals.MetaAddonModal, {
      disabled: this.disabled,
      meta: this.meta,
      onChange: (meta: Meta) => { this.meta = meta },
      relatedService: this.relatedService,
      articleTitle: this.variant.title
    }, { size: ModalSize.xl })
  }
}

export default MetaAddon
