













































































import { AnyModule, AnyObject, DashmixIconName, DeMarkedTokenizerError } from '@d24/modules'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'
import { debounce, DebouncedFunc } from 'lodash'

import { Alert, AlertTheme } from '@component/Alert'
import { DeMarked } from '@/shared/helpers/demarked'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@plugin/inversify'
import { IRelatedService } from '@service/related'
import { MarkdownEditor } from '@component/MarkdownEditor'
import { Modals } from '@/config/modals'
import { ModulesRegistry, PageBuilder, PageBuilderOperationMode } from '@component/PageBuilder'
import { Property } from '@component/Property'

import { UserData } from '@module/auth/contracts/models'

import { articleModules } from '../maps/modules'
import { ContentType, OnDirtyCallback, OnMarkdownCallback, OnVariantChangeCallback } from '../contracts'
import { MarkdownEditorGuideModalPayload } from './MarkdownEditorGuideModal'
import { VariantModel } from '../contracts/models'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'ArticleVariant',
  components: { Alert, MarkdownEditor, PageBuilder, Property }
})
export class ArticleVariant extends Vue {
  @Prop({ type: Object, required: false, default: null })
  protected authUser!: UserData|null

  @Prop({ type: String, required: true })
  public contentType!: ContentType

  @Prop({ type: Object, required: false, default: null })
  public errors!: AnyObject

  @Prop({ type: Function, required: true })
  protected onDirty!: OnDirtyCallback

  @Prop({ type: Function, required: true })
  protected onVariantChange!: OnVariantChangeCallback

  @Prop({ type: Function, default: null })
  protected onMarkdown!: OnMarkdownCallback|null

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  @Prop({ type: Object, required: true })
  public variant!: VariantModel

  @Inject(ModalType)
  private readonly modalConnector!: IModal

  @Watch('variant.properties.meta', { deep: true, immediate: true })
  private onMetaChange (): void {
    this.validateTitle()
  }

  public readonly DashmixIconName = DashmixIconName

  public alertTheme: AlertTheme = AlertTheme.Danger
  public builderMode: PageBuilderOperationMode = PageBuilderOperationMode.EditModules
  public isEditorDirty: boolean = false
  public isModelValid: boolean = true
  public model: string = ''
  public modulesRegistry: ModulesRegistry = articleModules
  public validationError: DeMarkedTokenizerError|null = null
  public variantErrors: AnyObject = {}
  private initModel: string = ''

  private debouncer!: DebouncedFunc<(model: string) => void>
  private showPreview: boolean = false

  public get isLive (): boolean {
    return this.variant.isLive()
  }

  private get localizedRules (): string[]|undefined {
    return this.validationError?.rules[this.$i18n.locale]
  }

  public get titleError (): string | null {
    return this.variantErrors && this.variantErrors.title && this.variantErrors.title.length > 0
      ? this.variantErrors.title[0] : null
  }

  public get title (): string {
    return this.variant.title
  }

  public set title (value: string) {
    this.variant.set('title', value)
    this.variantErrors = {}
    this.validateTitle()
    this.onVariantChange(
      this.variant
    )
  }

  /**
   * Returns the length of the longest meta title.
   * Also takes into account the title of the article itself, because it's copied on article publish.
   */
  public getLongestMetaTitleLength (): number {
    const meta = this.variant.properties?.meta
    if (!meta) {
      return this.variant.title.length
    }

    const title = meta.title || ''
    const ogTitle = meta.ogTitle || ''
    const twitterTitle = meta.twitterTitle || ''

    const titleLength = Math.max(
      title.length,
      ogTitle.length,
      twitterTitle.length,
      this.missingMetaTitle ? this.variant.title.length : 0
    )
    return titleLength || this.variant.title.length
  }

  public get missingMetaTitle (): boolean {
    const meta = this.variant.properties?.meta
    if (!meta) {
      return true
    }
    return !meta.title || !meta.ogTitle || !meta.twitterTitle
  }

  private validateTitle () {
    const titleLength = this.getLongestMetaTitleLength()
    if (this.variantErrors.title) {
      Vue.delete(this.variantErrors, 'title')
    }
    const errors: string[] = []
    if (titleLength > 60) {
      errors.push(this.$t('content.article.titleTooLong', { max: 60 }) as string)
    }
    if (errors.length > 0) {
      Vue.set(this.variantErrors, 'title', errors)
    }
  }

  /**
   * Vue lifecycle hook.
   */
  created (): void {
    if (!this.isLive) {
      const initValue = DeMarked.convertObjectsToMD(this.variant.modules)
      this.initModel = initValue
      this.model = initValue

      this.debouncer = debounce((model: string) => {
        try {
          const modules = DeMarked.convertMDToObjects(model)
          this.variant.set('modules', modules)
          this.onVariantChange(
            this.variant
          )
        } catch (e) {
          console.warn(e)
        }
      }, 600)
    }

    if (this.isEditable()) {
      this.builderMode = this.isLive ? PageBuilderOperationMode.EditModules : PageBuilderOperationMode.Render
    }
  }

  public isEditable (): boolean {
    return this.variant.isEditable(this.authUser ? (this.authUser.id as Identifier) : null)
  }

  public isPreview (): boolean {
    return !this.isEditable() || this.showPreview
  }

  /**
   * Handles the `@dirty` event on the `<MarkdownEditor>` component.
   */
  public onEditorDirty (): void {
    this.isEditorDirty = true
    this.onDirty(true)
    this.variantErrors = {}
  }

  /**
   * Handles the `@input:invalid` event on the `<MarkdownEditor>` component.
   */
  public onEditorInvalidInput (e?: DeMarkedTokenizerError): void {
    if (typeof e !== 'undefined') {
      this.validationError = e
      this.isModelValid = false
    }

    this.isModelValid = true
  }

  /**
   * Handles the `@input:valid` event on the `<MarkdownEditor>` component.
   */
  public onEditorValidInput (): void {
    this.isModelValid = true
  }

  /**
   * Handles the @click event on the "help" button.
   */
  public onHelpBtnClick (): void {
    this.openHelpModal()
  }

  /**
   * Handles the `@blur` event on the `<MarkdownEditor>` component.
   */
  public onMarkdownEditorBlur (): void {
    this.flushDebounce()
  }

  /**
   * Methods to handle page builder mode
   */
  public onModulesChange (changed: AnyModule[]): void {
    this.variant.set('modules', changed)
    this.onVariantChange(this.variant)
  }

  public pageBuilderOperationMode (): PageBuilderOperationMode {
    return this.isEditable() ? this.builderMode : PageBuilderOperationMode.Render
  }

  public setPageBuilderOperationMode (value: PageBuilderOperationMode) {
    this.builderMode = value
  }

  public shouldDisplayMarkdownEditor (): boolean {
    const state = !this.isPreview() && !this.isLive
    if (this.onMarkdown) {
      this.onMarkdown(state)
    }
    return state
  }

  public shouldDisplayPageBuilder (): boolean {
    return this.isPreview() || this.isLive
  }

  public shouldDisplayPreviewButton (): boolean {
    return this.isEditable() && !this.isLive
  }

  public shouldShowPageBuilderControls (): boolean {
    return !this.isPreview() && this.isLive
  }

  public togglePreview (): void {
    this.showPreview = !this.showPreview
  }

  private flushDebounce (): void {
    this.debouncer.flush()
  }

  @Watch('model')
  private onModelChange (model: string, old: string): void {
    if (this.isEditable() && old !== model && this.initModel !== model) {
      // FIXME
      // const convertedModel = replaceAsteriskWithStrongTag(model)
      this.debouncer(model)
    }
  }

  /**
   * Opens the "help" modal with the applicable payload.
   */
  private openHelpModal (): void {
    const payload: MarkdownEditorGuideModalPayload = {
      rules: this.localizedRules
    }

    this.modalConnector.open(Modals.MarkdownEditorGuide, payload)
  }

  @Watch('errors', { deep: true })
  public onErrorsChange (err: AnyObject): void {
    if (err && err.variant && err.variant[this.variant.id]) {
      this.variantErrors = err.variant[this.variant.id]
    } else {
      this.variantErrors = {}
    }
  }
}
export default ArticleVariant
