// Copyright © 2021 Move Closer

import {
  AboutModuleForm,
  AdBannerModuleForm,
  ArticlesListModuleForm,
  AuthorCardModuleForm,
  BlockquoteModuleForm,
  BoxesModuleForm,
  ContactModuleForm,
  EditorialOfficeModuleForm,
  ExpertsModuleForm,
  EmbedModuleForm,
  GalleryModuleForm,
  HeadingModuleForm,
  HeroModuleForm,
  HRModuleForm,
  ImageModuleForm,
  ListModuleForm,
  LiveFeedModuleForm,
  LogosModuleForm,
  ModuleDriver,
  NewsBarModuleForm,
  NewsletterModuleForm,
  PaginationModuleForm,
  PartnersModuleForm,
  ParagraphModuleForm,
  QuotesModuleForm,
  RandomImageModuleForm,
  ReadMoreModuleForm,
  RelatedArticlesModuleForm,
  StickyBarModuleForm,
  TeaserBoxModuleForm,
  TestimonialsModuleForm,
  TileModuleForm, HTMLModuleForm
} from '@d24/modules'

import { ModulesRegistry } from '@component/PageBuilder'
import { RequestAccessModuleForms } from '@module/forms/shared/RequestAccess'

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const articleModules: ModulesRegistry = {
  [ModuleDriver.Blockquote]: {
    form: BlockquoteModuleForm,
    image: require('@/assets/images/modules/blockquote.svg'),
    name: ModuleDriver.Blockquote
  },
  [ModuleDriver.Embed]: {
    form: EmbedModuleForm,
    image: require('@/assets/images/modules/embed.svg'),
    name: ModuleDriver.Embed
  },
  // [ModuleDriver.HR]: {
  //   form: HRModuleForm,
  //   image: require('@/assets/images/modules/tile.svg'),
  //   name: ModuleDriver.HR
  // },
  [ModuleDriver.Heading]: {
    form: HeadingModuleForm,
    image: require('@/assets/images/modules/heading.svg'),
    name: ModuleDriver.Heading
  },
  [ModuleDriver.Image]: {
    form: ImageModuleForm,
    image: require('@/assets/images/modules/image.svg'),
    name: ModuleDriver.Image
  },
  [ModuleDriver.List]: {
    form: ListModuleForm,
    image: require('@/assets/images/modules/list.svg'),
    name: ModuleDriver.List
  },
  [ModuleDriver.LiveFeed]: {
    form: LiveFeedModuleForm,
    image: require('@/assets/images/modules/tile.svg'),
    name: ModuleDriver.LiveFeed
  },
  [ModuleDriver.Paragraph]: {
    form: ParagraphModuleForm,
    image: require('@/assets/images/modules/paragraph.svg'),
    name: ModuleDriver.Paragraph
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const pageModules: ModulesRegistry = {
  [ModuleDriver.HTML]: {
    form: HTMLModuleForm,
    image: require('@/assets/images/modules/html.svg'),
    name: ModuleDriver.HTML
  },
  [ModuleDriver.TeaserBox]: {
    form: TeaserBoxModuleForm,
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.TeaserBox
  },
  [ModuleDriver.Tile]: {
    form: TileModuleForm,
    image: require('@/assets/images/modules/tile.svg'),
    name: ModuleDriver.Tile
  },
  [ModuleDriver.ArticlesList]: {
    form: ArticlesListModuleForm,
    image: require('@/assets/images/modules/articles_list.svg'),
    name: ModuleDriver.ArticlesList
  },
  [ModuleDriver.NewsBar]: {
    form: NewsBarModuleForm,
    image: require('@/assets/images/modules/news_bar.svg'),
    name: ModuleDriver.NewsBar
  },
  [ModuleDriver.AdBanner]: {
    form: AdBannerModuleForm,
    image: require('@/assets/images/modules/ad_banner.svg'),
    name: ModuleDriver.AdBanner
  },
  [ModuleDriver.ReadMore]: {
    form: ReadMoreModuleForm,
    image: require('@/assets/images/modules/read_more.svg'),
    name: ModuleDriver.ReadMore
  },
  [ModuleDriver.Image]: {
    form: ImageModuleForm,
    image: require('@/assets/images/modules/image.svg'),
    name: ModuleDriver.Image
  },
  [ModuleDriver.RandomImage]: {
    form: RandomImageModuleForm,
    image: require('@/assets/images/modules/image.svg'),
    name: ModuleDriver.RandomImage
  },
  [ModuleDriver.Gallery]: {
    form: GalleryModuleForm,
    image: require('@/assets/images/modules/gallery.svg'),
    name: ModuleDriver.Gallery
  },
  [ModuleDriver.Heading]: {
    form: HeadingModuleForm,
    image: require('@/assets/images/modules/heading.svg'),
    name: ModuleDriver.Heading
  },
  [ModuleDriver.Paragraph]: {
    form: ParagraphModuleForm,
    image: require('@/assets/images/modules/paragraph.svg'),
    name: ModuleDriver.Paragraph
  },
  [ModuleDriver.Blockquote]: {
    form: BlockquoteModuleForm,
    image: require('@/assets/images/modules/blockquote.svg'),
    name: ModuleDriver.Blockquote
  },
  [ModuleDriver.List]: {
    form: ListModuleForm,
    image: require('@/assets/images/modules/list.svg'),
    name: ModuleDriver.List
  },
  [ModuleDriver.Embed]: {
    form: EmbedModuleForm,
    image: require('@/assets/images/modules/embed.svg'),
    name: ModuleDriver.Embed
  },
  [ModuleDriver.Pagination]: {
    form: PaginationModuleForm,
    image: require('@/assets/images/modules/pagination.svg'),
    name: ModuleDriver.Pagination
  },
  [ModuleDriver.RelatedArticles]: {
    form: RelatedArticlesModuleForm,
    image: require('@/assets/images/modules/articles_list.svg'),
    name: ModuleDriver.RelatedArticles
  },
  [ModuleDriver.Partners]: {
    form: PartnersModuleForm,
    image: require('@/assets/images/modules/news_bar.svg'),
    name: ModuleDriver.Partners
  },
  [ModuleDriver.About]: {
    form: AboutModuleForm,
    // TODO - change image
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.About
  },
  [ModuleDriver.Boxes]: {
    form: BoxesModuleForm,
    // TODO - change image
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.Boxes
  },
  [ModuleDriver.AuthorCard]: {
    form: AuthorCardModuleForm,
    // TODO - change image
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.AuthorCard
  },
  [ModuleDriver.EditorialOffice]: {
    form: EditorialOfficeModuleForm,
    // TODO - change image
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.EditorialOffice
  },
  [ModuleDriver.Quotes]: {
    form: QuotesModuleForm,
    // TODO - change image
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.Quotes
  },
  [ModuleDriver.Contact]: {
    form: ContactModuleForm,
    // TODO - change image
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.Contact
  },
  [ModuleDriver.Experts]: {
    form: ExpertsModuleForm,
    // TODO - change image
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.Experts
  },
  [ModuleDriver.RequestAccess]: {
    form: RequestAccessModuleForms,
    // TODO - change image
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.RequestAccess
  },
  [ModuleDriver.Newsletter]: {
    form: NewsletterModuleForm,
    // TODO - change image
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.Newsletter
  },
  [ModuleDriver.Logos]: {
    form: LogosModuleForm,
    // TODO - change image
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.Logos
  },
  [ModuleDriver.Hero]: {
    form: HeroModuleForm,
    // TODO - change image
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.Hero
  },
  [ModuleDriver.StickyBar]: {
    form: StickyBarModuleForm,
    // TODO - change image
    image: require('@/assets/images/modules/news_bar.svg'),
    name: ModuleDriver.StickyBar
  },
  [ModuleDriver.Testimonials]: {
    form: TestimonialsModuleForm,
    // TODO - change image
    image: require('@/assets/images/modules/teaser_box.svg'),
    name: ModuleDriver.Testimonials
  }
}
