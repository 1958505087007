// Copyright © 2021 Move Closer

import { ICollection, IModel, MagicModel } from '@movecloser/front-core'
import { ImageRatio } from '@d24/modules'

import { AuthorModel } from '@module/content/contracts'
import { HasIdentifier, HasPending } from '@/shared/contracts/data'
import { FileEditPayload } from '@module/media/contracts/data'

export interface MediaData extends FileData, DirectoryData {
  type: string
}

export interface IDirectory extends IModel<DirectoryData> {
  childrenCount (): number

  directoryCount (): number

  fileCount (): number

  fullRoute (): string
}

export interface IFile extends IModel<FileData> {
  addedByFullName (): string

  fullRoute (): string

  toEditPayload (): FileEditPayload
}

export interface IGallery extends IModel<GalleryData> {
  authorName (): string

  getItemCount (): number
}

export interface ArchiveDescriptions {
  author: string
  caption: string
}

export interface FileData extends HasIdentifier, HasPending {
  action: FileDataActions
  addedBy: AuthorModel
  author: string
  archiveDescriptions: ArchiveDescriptions[]
  alt: string
  createdAt: string
  caption: string | null
  deletedAt: null
  description: string
  directory: DirectoryParentData
  disk: string
  display: null
  file: FileDetails
  redirectExt?: string
  m3u8?: string
  mime: string
  name: string
  note: null
  width: number
  height: number
  original: FileDetails
  preset: string
  public: boolean
  size: number
  title: string
  updatedAt: string
  url: string
  position: number
  preview?: boolean
}

export enum FileDataActions {
  None = 'none',
  Preview = 'preview',
  Link = 'link'
}

export interface FileDetails {
  file?: string
  name?: string
  thumbnail?: string
  status?: { [key: string]: VideoVariantParams }
  variants?: FileVariant[]
  variantsParams?: { [key: string]: FileVariantParams }
}

export interface VideoVariantParams {
  audio: string | null
  m3u8: string | null
  push: string | null
  thumbnail: string | null
  video: string | null
}

export interface VideoVariantStatus {
  value: number
  status: string
  label: string
}

export interface FileVariant {
  url: string
  width: number
  height: number | null
  ratio: ImageRatio
}

export interface FileVariantParams {
  action: string
  params: {
    coordinates: {
      width: number
      height: number
      left: number
      top: number
    }
  }
  ratio: string
  state?: string
}

export interface DirectoryData extends HasIdentifier {
  createdAt: string
  directories: ICollection<DirectoryModel>
  elements: number
  files: ICollection<FileModel>
  name: string
  parent: DirectoryParentData | null
  nodes: ICollection<DirectoryNodeData> // tmp
  updatedAt: string
}

export interface DirectoryParentData extends HasIdentifier {
  name: string
  parent: DirectoryParentData | null
}

export interface DirectoryNodeData extends HasIdentifier {
  name: string
  nodes: ICollection<DirectoryNodeData>
}

export interface GalleryData extends HasIdentifier {
  editor: AuthorModel
  createdAt?: string
  childrenCount: number
  title: string
  description: string
  updatedAt?: string
}

export enum GalleryType {
  Gallery = 'gallery'
}

export type FileModel = MagicModel<FileData, IFile>
export type DirectoryModel = MagicModel<DirectoryData, IDirectory>
export type GalleryModel = MagicModel<GalleryData, IGallery>
